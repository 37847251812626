import { Controller } from '@hotwired/stimulus';
import { load } from 'recaptcha-v3';
import {getMeta} from '../helpers';

export default class extends Controller {

  setupFormAction(action, actionData) {
    switch (action) {
      case 'reset_passwords':
        this.element.querySelectorAll('input[type="password"]').forEach((el) => {
          el.value = '';
        });
        break;

      case 'remove_elements':
        if (actionData.length > 0) {
          actionData.forEach((el) => {
            document.getElementById(el).remove();
          });
        }
        break;

      case 'replace_content':
        if (actionData) {
          this.element.classList.remove('loading');
          this.element.innerHTML = actionData;
        }
        break;
    }
  }

  async connect() {
    this.currentLanguageCode = document.documentElement.lang.split('-')[0];
    if (this.currentLanguageCode === 'nn') {
      this.currentLanguageCode = 'no';
    }
    const siteKey = this.element.hasAttribute('data-site-key') ? this.element.getAttribute('data-site-key') : undefined;
    const recaptcha = siteKey ? await load(siteKey) : undefined;

    this.element.addEventListener('submit', async (e) => {
      e.preventDefault();

      if (this.element.classList.contains('loading')) {
        return;
      }

      this.element.classList.add('loading');
      const token = recaptcha ? await recaptcha.execute('submit') : undefined;
      await this.submitForm(token);
    });

  }

  async submitForm(token) {
    const url = this.element.action + '?rest_language=' + this.currentLanguageCode;
    const method = this.element.method.toLowerCase();
    const data = new FormData(this.element);
    if (token) {
      data.append('token', token);
    }

    let response;

    try {
      if (method === 'get') {
        response = await axios.get(url);
      } else if (method === 'post') {
        response = await this.handlePostSubmit(url, data, { headers: { 'Content-Type': 'multipart/form-data' } });
      }

      response = response.data.data;
    } catch (error) {
      response = error.response.data.data;
    }

    this.removeFormNotes();

    if (response && response.action) {
      this.setupFormAction(response.action, response.action_data);
    }

    if (response && response.errors && Object.keys(response.errors).length) {
      this.setupFormErrors(response.errors);

    } else if (response && response.general_alert) {
      this.element.classList.remove('loading');
      alert(response.general_alert);

    } else if (response && response.general_error) {
      this.setupFormGeneralError(response);

    } else if (response && response.general_success) {
      this.setupFormGeneralSuccess(response);

    } else if (response && response.redirect) {
      window.location.href = response.redirect;

    } else if (response && response.reload) {
      window.location.reload();

    } else {
      if (!response || !response.action) {
        this.setupFormUnexpectedError();
      }
    }
  }

  handlePostSubmit(url, data, config) {
    if (window.grecaptcha && data instanceof FormData) {
      return new Promise((resolve) => {
        window.grecaptcha.ready(() => {
          resolve(window.grecaptcha.execute(getMeta('recaptcha_site_key'), {
            action: 'submit'
          }));
        });
      }).then(token => {
        data.append('token', token);
        return axios.post(url, data, config);
      });
    }

    return axios.post(url, data, config);
  }

  setupFormUnexpectedError() {
    const messageNode = document.createElement('span');
    messageNode.classList.add('general-error');
    messageNode.innerHTML = document.querySelector('meta[name="global_axios_validator_error"]').content;

    const generalInfoHolder = this.element.querySelector('.general-info-holder');
    if (generalInfoHolder) {
      generalInfoHolder.appendChild(messageNode);
    } else {
      this.element.appendChild(messageNode);
    }

    this.element.classList.remove('loading');
  }

  setupFormGeneralSuccess(response) {
    const messageNode = document.createElement('span');
    messageNode.classList.add('general-success');
    messageNode.innerHTML = response.general_success;

    if (response.hide) {
      messageNode.classList.add('p-0-i');

      for (const el of this.element.querySelectorAll('.axios-hide')) {
        el.classList.add('d-none');
      }
    }

    if (response.clear) {
      for (const el of this.element.querySelectorAll('.axios-clear')) {
        el.value = '';
      }
    }

    const generalInfoHolder = this.element.querySelector('.general-info-holder');
    if (generalInfoHolder) {
      generalInfoHolder.appendChild(messageNode);
    } else {
      this.element.appendChild(messageNode);
    }

    this.element.classList.remove('loading');
  }

  setupFormGeneralError(response) {
    const messageNode = document.createElement('span');
    messageNode.classList.add('general-error');
    messageNode.innerHTML = response.general_error;

    const generalInfoHolder = this.element.querySelector('.general-info-holder');
    if (generalInfoHolder) {
      generalInfoHolder.appendChild(messageNode);
    } else {
      this.element.appendChild(messageNode);
    }

    this.element.classList.remove('loading');
  }

  setupFormErrors(errors) {
    for (let i = 0, keys = Object.keys(errors), ii = keys.length; i < ii; i++) {
      const input = this.element.querySelector('[name="' + keys[i] + '"]');

      if (input) {
        input.classList.add('invalid');

        const messageNode = document.createElement('span');
        messageNode.classList.add('input-error');
        messageNode.innerHTML = errors[keys[i]];

        if (input.parentNode.classList.contains('input-wrap')) {
          input.parentNode.parentNode.appendChild(messageNode);
        } else {
          input.parentNode.insertBefore(messageNode, input.nextSibling);
        }
      }
    }

    this.element.classList.remove('loading');
  }

  removeFormNotes() {
    const generalErrors = this.element.querySelectorAll('.general-error');
    for (const el of generalErrors) {
      if (!el.classList.contains('axios-no-remove')) {
        el.remove();
      }
    }

    const generalSuccess = this.element.querySelectorAll('.general-success');
    for (const el of generalSuccess) {
      if (!el.classList.contains('axios-no-remove')) {
        el.remove();
      }
    }

    const fields = this.element.querySelectorAll('.input');
    for (const el of fields) {
      el.classList.remove('invalid');
    }

    const messageFields = this.element.querySelectorAll('.input-error');
    for (const el of messageFields) {
      el.remove();
    }
  }

}
