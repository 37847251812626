export function hide(el) {
	el.style.display = 'none';
}

export function show(el, display = 'block') {
	el.style.display = display;
}

export function fadeIn(el, display = 'block') {
	el.style.opacity = 0;
	el.style.display = display;
	(function fade() {
		var val = parseFloat(el.style.opacity);
		if (!((val += .1) > 1)) {
			el.style.opacity = val;
			requestAnimationFrame(fade);
		}
	})();
}

export function fadeOut(el) {
	el.style.opacity = 1;
	(function fade() {
		if ((el.style.opacity -= .1) < 0) {
			el.style.display = 'none';
		} else {
			requestAnimationFrame(fade);
		}
	})();
}

export function slideUp(el, duration, callback) {
	slide(el, duration, callback);
}

export function slideDown(el, duration, callback) {
	slide(el, duration, callback, true);
}

export function slideToggle(el, duration, callback) {
	if (el.clientHeight === 0) {
		slide(el, duration, callback, true);
	} else {
		slide(el, duration, callback);
	}
}

function slide(el, duration, callback, isDown) {

	if (typeof duration === 'undefined') duration = 400;
	if (typeof isDown === 'undefined') isDown = false;

	el.style.overflow = 'hidden';
	if (isDown) el.style.display = 'block';

	const elStyles = window.getComputedStyle(el);

	const elHeight = parseFloat(elStyles.getPropertyValue('height'));
	const elPaddingTop = parseFloat(elStyles.getPropertyValue('padding-top'));
	const elPaddingBottom = parseFloat(elStyles.getPropertyValue('padding-bottom'));
	const elMarginTop = parseFloat(elStyles.getPropertyValue('margin-top'));
	const elMarginBottom = parseFloat(elStyles.getPropertyValue('margin-bottom'));

	const stepHeight        = elHeight        / duration;
	const stepPaddingTop    = elPaddingTop    / duration;
	const stepPaddingBottom = elPaddingBottom / duration;
	const stepMarginTop     = elMarginTop     / duration;
	const stepMarginBottom  = elMarginBottom  / duration;

	let start;

	function step(timestamp) {

		if (start === undefined) start = timestamp;

		var elapsed = timestamp - start;

		if (isDown) {
			el.style.height        = (stepHeight        * elapsed) + 'px';
			el.style.paddingTop    = (stepPaddingTop    * elapsed) + 'px';
			el.style.paddingBottom = (stepPaddingBottom * elapsed) + 'px';
			el.style.marginTop     = (stepMarginTop     * elapsed) + 'px';
			el.style.marginBottom  = (stepMarginBottom  * elapsed) + 'px';
		} else {
			el.style.height        = elHeight        - (stepHeight        * elapsed) + 'px';
			el.style.paddingTop    = elPaddingTop    - (stepPaddingTop    * elapsed) + 'px';
			el.style.paddingBottom = elPaddingBottom - (stepPaddingBottom * elapsed) + 'px';
			el.style.marginTop     = elMarginTop     - (stepMarginTop     * elapsed) + 'px';
			el.style.marginBottom  = elMarginBottom  - (stepMarginBottom  * elapsed) + 'px';
		}

		if (elapsed >= duration) {
			el.style.height        = '';
			el.style.paddingTop    = '';
			el.style.paddingBottom = '';
			el.style.marginTop     = '';
			el.style.marginBottom  = '';
			el.style.overflow      = '';
			if (!isDown) el.style.display = 'none';
			if (typeof callback === 'function') callback();
		} else {
			window.requestAnimationFrame(step);
		}
	}

	window.requestAnimationFrame(step);
}

export function domReady(fn) {
	// If we're early to the party
	document.addEventListener('DOMContentLoaded', fn);
	// If late; I mean on time.
	if (document.readyState === 'interactive' || document.readyState === 'complete' ) {
		fn();
	}
}

export function removeFromArray(arr) {
	let what, a = arguments, L = a.length, ax;
	while (L > 1 && arr.length) {
		what = a[--L];
		while ((ax= arr.indexOf(what)) !== -1) {
			arr.splice(ax, 1);
		}
	}
	return arr;
}

export function isLocal(url) {
	return url.includes('localhost') || url.includes('127.0.0.1');
}

export function getMeta(metaName) {
	const metaEl = document.querySelector(`meta[name='${metaName}']`);
	if (!metaEl) {
		return '';
	}

	return metaEl.getAttribute('content');
}
