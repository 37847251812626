export default function initCookies() {
    const cookieBar = document.querySelector('.cookie-bar');
    const cookieFloater = document.querySelector('.cookie-floater');

    if (cookieFloater && cookieBar) {
        const cookieClose = cookieBar.querySelector('.cookie-close');

        cookieFloater.addEventListener('click', () => {
            cookieBar.classList.remove('d-none');
            cookieFloater.classList.add('d-none');

            animateOut(cookieFloater,() => {}, () => {
                cookieFloater.classList.add('d-none');
            });

            animateIn(cookieBar, () => {
                cookieBar.classList.remove('d-none');
            }, () => {});
        });

        if (cookieClose) {
            cookieClose.addEventListener('click', () => {
                animateOut(cookieBar,() => {}, () => {
                    cookieBar.classList.add('d-none');
                });

                animateIn(cookieFloater, () => {
                    cookieFloater.classList.remove('d-none');
                }, () => {});
            });
        }

        for (const cookieSubmit of cookieBar.querySelectorAll('.cookie-submit')) {
            cookieSubmit.addEventListener('click', () => {
                const functional = cookieBar.querySelector('input[name=cookie_functional]').checked;
                const analytics = cookieBar.querySelector('input[name=cookie_analytics]').checked;
                const acceptAll = cookieSubmit.classList.contains('accept-all');

                const d = new Date();
                d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
                const expires = 'expires=' + d.toGMTString();

                document.cookie = 'consents_essentials=1;' + expires + ';path=/';

                if (acceptAll) {
                    document.cookie = 'consents_functional=1;' + expires + ';path=/';
                    document.cookie = 'consents_analytics=1;' + expires + ';path=/';
                } else {
                    document.cookie = 'consents_functional=' + (functional ? '1' : '0') + ';' + expires + ';path=/';
                    document.cookie = 'consents_analytics=' + (analytics ? '1' : '0') + ';' + expires + ';path=/';
                }

                animateOut(cookieBar,() => {}, () => {
                    cookieBar.classList.add('d-none');
                });

                animateIn(cookieFloater, () => {
                    cookieFloater.classList.remove('d-none');
                }, () => {});

                location.reload();
            });
        }
    }

    if (!cookieBar.classList.contains('essentials-accepted')) {
        setTimeout(()=>{
            if (window.screen.width >= 768) {
                animateIn(cookieBar, () => {
                    cookieBar.classList.remove('d-none');
                }, () => {
                });
            } else {
                animateIn(cookieFloater, () => {
                    cookieFloater.classList.remove('d-none');
                }, () => {
                });
            }
        }, 5000);
    }
}

function animateIn(target, before, callback) {
    let op = 0.1;
    target.style.opacity = op;
    before();
    let timer = setInterval(function () {
        if (op >= 1) {
            clearInterval(timer);
            callback();
        }
        target.style.opacity = op;
        target.style.filter = 'alpha(opacity=' + op * 100 + ')';
        op += op * 0.1;
    }, 5);
}

function animateOut(target, before, callback) {
    let op = 1;
    target.style.opacity = op;
    before();
    let timer = setInterval(function () {
        if (op <= 0.1) {
            clearInterval(timer);
            callback();
        }
        target.style.opacity = op;
        target.style.filter = 'alpha(opacity=' + op * 100 + ')';
        op -= op * 0.1;
    }, 5);
}