import { fadeIn, fadeOut } from '../helpers';

export function initModal() {
	let modalInitiators = document.querySelectorAll('*[data-modal]');
	let modalCloseBtns = document.querySelectorAll('.modal-close');
	Array.from(modalInitiators).forEach(modalInitiator => {
		modalInitiator.addEventListener('click', function(e) {
				e.preventDefault();
				document.body.classList.add('modal--open');
				let targetModal = e.target.getAttribute('data-modal');
				fadeIn(document.getElementById(targetModal));
		});
	});

	Array.from(modalCloseBtns).forEach(modalCloseBtn => {
		modalCloseBtn.addEventListener('click', function(e) {
			e.preventDefault();
			document.body.classList.remove('modal--open');
			fadeOut(e.target.closest('.modal'));
		});
	});
}
