import {Controller} from '@hotwired/stimulus';
import { createPopper } from '@popperjs/core';

export default class extends Controller {
    connect() {
        const data = this.element.dataset;
        const offsetX = Number(data.offsetX);
        const offsetY = Number(data.offsetY);
        ['mouseenter','touchstart'].forEach( evt =>
            this.element.addEventListener(evt, ()=>{
                const tooltip = this.element.querySelector('.tooltip');
                createPopper(this.element, tooltip, {
                    placement: 'top',
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [offsetX ? offsetX : 0, offsetY ? offsetY : 0],
                            },
                        },
                    ],
                });
                tooltip.style.display = 'block';

                ['mouseleave','touchend'].forEach( evt =>
                    this.element.addEventListener(evt, ()=> {
                        tooltip.style.display = 'none';
                    }, false)
                );


            }, false)
        );
    }
}