import {Controller} from '@hotwired/stimulus';

import markerUrl from '../../images/icons/marker.svg';
import Leaflet from 'leaflet/dist/leaflet.js';

export default class extends Controller {
  connect() {
    this.position = JSON.parse(this.data.get('coordinates'));
    const directions = JSON.parse(this.data.get('directions'));
    const zoom = this.data.get('zoom');

    this.currentLines = [];

    this.map = Leaflet.map(this.element.querySelector('#map'), {
      center: this.position,
      zoom: zoom ? zoom : 15,
      scrollWheelZoom: false,
    });

    Leaflet.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{tileSize}/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: '© <a href="https://www.mapbox.com/contribute/">Mapbox</a>',
      id: 'oginskasd/cl6aeoqly000m14mmwxtio4rc',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1Ijoib2dpbnNrYXNkIiwiYSI6ImNreDNtdzgxaTIwZ24ycHFycnVsa295NmoifQ.HFDL1bkuvIc3u396YqrVEA'
    }).addTo(this.map);

    Leaflet.marker(this.position, {
      icon: new (Leaflet.Icon.extend({
        options: {
          // iconSize:     [166, 138],
          iconAnchor:   [25, 75]
        }
      }))({iconUrl: markerUrl})
    }).addTo(this.map);

    directions.forEach((direction)=>{
      Leaflet.marker(direction.coordinates, {
        icon: new (Leaflet.Icon.extend({
          options: {
            iconAnchor: [25, 25]
          }
        }))({iconUrl: direction.marker})
      }).addTo(this.map);
    });
  }

  focusOnMarker(event) {
    let data = event.currentTarget.dataset;
    let coordinates = [data.lat, data.long];
    this.map.flyTo(coordinates);

    var pointA = new L.LatLng(coordinates[0], coordinates[1]);
    var pointB = new L.LatLng(this.position[0], this.position[1]);
    var pointList = [pointA, pointB];

    this.currentLines.forEach((line)=>{
      this.map.removeLayer(line);
    });

    let line = new L.Polyline(pointList, {
      color: 'black',
      weight: 3,
      opacity: 1,
      smoothFactor: 1,
      dashArray: '5, 10',
      lineCap: 'square', // Optional, just to avoid round borders.
    }).addTo(this.map);

    this.element.querySelectorAll('.right-panel .item').forEach((el)=>{
      el.classList.remove('active');
    });
    event.currentTarget.classList.add('active');

    this.currentLines.push(line);
  }
}
