import leftArrowUrl from '../../images/icons/arrow-left.svg';
import rightArrowUrl from '../../images/icons/arrow-right.svg';

import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {

        const url = new URL(window.location.href);
        const urlPage = parseInt(url.searchParams.get('boats_page'));
        this.adminAjaxUrl = '/wp-admin/admin-ajax.php';

        this.boatsWrapper = this.element.querySelector('#boatsWrapper');
        this.currentPage = (urlPage ? urlPage : 1);
        this.maxPages = 1;

        this.currentTimeout = null;

        this.loadPosts(0);
    }

    loadPosts(delay) {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = setTimeout(() => {
            this.boatsWrapper.innerHTML = '';
            this.boatsWrapper.append(this.createLoadingSpinner());

            let formData = new FormData;
            formData.append('action', 'boats_load_posts');
            formData.append('paged', this.currentPage);

            axios.post(this.adminAjaxUrl, formData).then((response)=>{
                if (response.data.html) {
                    this.boatsWrapper.querySelector('#loadingSpinner').remove();
                    this.boatsWrapper.innerHTML += response.data.html;
                    this.maxPages = parseInt(response.data.max);
                    this.addPagination();
                }
            });
        }, delay);
    }

    switchPage(event) {
        let data = event.currentTarget.dataset;
        if (event.currentTarget.classList.contains('prev-page')) {
            this.currentPage--;
        }
        if (event.currentTarget.classList.contains('next-page')) {
            this.currentPage++;
        }
        if (data.page) {
            this.currentPage = parseInt(data.page);
        }
        //window.history.replaceState(null, null, '?boats_page='+this.currentPage);
        this.loadPosts(250);
    }

    createLoadingSpinner() {
        let loadingSpinnerContainer = document.createElement('div');
        let loadingSpinner = document.createElement('div');
        loadingSpinnerContainer.setAttribute('id', 'loadingSpinner');
        loadingSpinnerContainer.classList.add('spinner-container');
        loadingSpinner.classList.add('spinner');
        loadingSpinnerContainer.append(loadingSpinner);

        return loadingSpinnerContainer;
    }

    addPagination() {
        let paginationList = document.createElement('ul');
        paginationList.classList.add('pagination', 'list-unstyled');

        let paginationContainer = this.element.querySelector('#paginationNav');
        paginationContainer.innerHTML = '';


        if (this.maxPages > 0) {
            if (this.currentPage - 1 >= 1) {
                paginationList.innerHTML +=
                    '<li class="page-item">' +
                    '<a role="button" tabindex="0" class="page-link prev-page" data-action="boats-page#switchPage">' +
                    `<img src="${leftArrowUrl}" alt="Left pagination arrow" width="16" height="16">` +
                    '</a>' +
                    '</li>';

            } else {
                paginationList.innerHTML +=
                    '<li class="page-item disabled">' +
                    `<img src="${leftArrowUrl}" alt="Left pagination arrow" width="16" height="16">` +
                    '</li>';
            }

            let iter = 1;
            while (iter <= this.maxPages) {
                paginationList.innerHTML += '<li class="page-item">' +
                    `<a role="button" tabindex="0" class="page-link ${(iter === this.currentPage ? 'active' : '')}" data-page="${iter}" data-action="boats-page#switchPage">${iter}</a>` +
                    '</li>';
                iter++;
            }
            if (this.currentPage + 1 <= this.maxPages) {
                paginationList.innerHTML +=
                    '<li class="page-item">' +
                    '<a role="button" tabindex="0" class="page-link next-page" data-action="boats-page#switchPage">' +
                    `<img src="${rightArrowUrl}" alt="Left pagination arrow" width="16" height="16">` +
                    '</a>' +
                    '</li>';

            } else {
                paginationList.innerHTML +=
                    '<li class="page-item disabled">' +
                    `<img src="${rightArrowUrl}" alt="Left pagination arrow" width="16" height="16">` +
                    '</li>';
            }
        }
        paginationContainer.append(paginationList);
    }


    resetPagination() {
        this.currentPage = 1;
    }

    clearElements(parent, keep) {
        [...parent.children]
            .forEach(child => child !== keep ? parent.removeChild(child) : null);
    }


}