import moment from 'moment';
import flatpickr from 'flatpickr';

export function initCalendar() {
    let calendarInstance;

    function updateCalendar(start, end, room_id, property_id) {
        const data = {
            start_date: start,
            end_date: end,
            room_id: room_id,
            property_id: property_id,
        };
        fetch('/wp-json/api/availability', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {

                const dates_available = data.data.dates_available;
                const jsonObject = JSON.parse(dates_available);
                const allowedDates = jsonObject.filter(date => moment(date).format('YYYY-MM-DD'));

                calendarInstance.set('enable', allowedDates);
            })
            .catch(error => console.error(error));
    }

    const start = moment();
    const end = moment().endOf('month');
    const room_id = document.getElementById('room_id');
    const property_id = document.getElementById('property_id');

    if (room_id && property_id) {
        updateCalendar(start, end, room_id.value, property_id.value);
    }

    calendarInstance = flatpickr('#calendar', {
        inline: true,
        minDate: 'today',
        allowInput: false,
        static: true,
        showMonths: 1,
        weekNumbers: true,
        showNextMonth: false,
        monthSelectorType: 'static',
        prevArrow: '<span class="flatpickr-prev-month-arrow"></span>',
        nextArrow: '<span class="flatpickr-next-month-arrow"></span>',
        locale: {
            weekdays: {
              shorthand: ['s', 'm', 't', 'w', 't', 'f', 's'],
              longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            },
        },
        onMonthChange: function(selectedDates, dateStr, instance) {
            const year = instance.currentYear;
            const month = instance.currentMonth;
            const end = moment([year, month]).endOf('month');
            const room_id = document.getElementById('room_id');
            const property_id = document.getElementById('property_id');
            if (room_id && property_id) {
                updateCalendar(moment().format('YYYY-MM-DD'), end, room_id.value, property_id.value);
            }
        }
    });
}

export function initRequestFormCalendar() {
    flatpickr('#arriving', {
        minDate: 'today',
        allowInput: false,
        showMonths: 1,
        weekNumbers: true,
        showNextMonth: true,
        monthSelectorType: 'static',
        locale: {
            weekdays: {
                shorthand: ['s', 'm', 't', 'w', 't', 'f', 's'],
                longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            },
        },
    });
}
