import {hide, fadeIn, show, removeFromArray, isLocal} from '../helpers';
import leftArrowUrl from '../../images/icons/arrow-left.svg';
import rightArrowUrl from '../../images/icons/arrow-right.svg';

import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {

        const url = new URL(window.location.href);
        const urlPage = parseInt(url.searchParams.get('accom_page'));
        this.adminAjaxUrl = isLocal(url.href) ? '/wp-basecamp/wp-admin/admin-ajax.php' : '/wp-admin/admin-ajax.php';

        this.appliedFilters = this.element.querySelector('.applied-filters');
        this.accommodationsWrapper = this.element.querySelector('#accommodationsWrapper');
        this.currentPage = (urlPage ? urlPage : 1);
        this.maxPages = 1;

        this.currentTimeout = null;

        this.cabinSizes = [];
        this.maxPeople = [];
        this.minStay = [];
        this.facilities = [];

        this.loadPosts(0);
    }

    loadPosts(delay) {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = setTimeout(() => {
            this.accommodationsWrapper.innerHTML = '';
            this.accommodationsWrapper.append(this.createLoadingSpinner());

            let formData = new FormData;
            formData.append('action', 'accommodations_load_posts');
            formData.append('paged', this.currentPage);
            formData.append('cabinSizes', JSON.stringify(this.cabinSizes));
            formData.append('maxPeople', JSON.stringify(this.maxPeople));
            formData.append('minStay', JSON.stringify(this.minStay));
            formData.append('facilities', JSON.stringify(this.facilities));

            axios.post(this.adminAjaxUrl, formData).then((response)=>{
                if (response.data.html) {
                    this.accommodationsWrapper.querySelector('#loadingSpinner').remove();
                    this.accommodationsWrapper.innerHTML += response.data.html;
                    this.maxPages = parseInt(response.data.max);
                    this.addPagination();
                }
            });
        }, delay);
    }

    toggleFacility(e) {
        const el = e.target;
        let value = parseInt(e.target.value);
        this.toggleTag(e);
        if (el.checked) {
            this.facilities.push(value);
        } else {
            this.facilities = removeFromArray(this.facilities, value);
        }
        this.toggleCount(e.target.parentElement.parentElement.parentElement, this.facilities);
        this.loadPosts(1000);
        this.resetPagination();
    }

    toggleMinStay(e) {
        const el = e.target;
        let value = parseInt(e.target.value);
        this.toggleTag(e);
        if (el.checked) {
            this.minStay.push(value);
        } else {
            this.minStay = removeFromArray(this.minStay, value);
        }
        this.toggleCount(e.target.parentElement.parentElement.parentElement, this.minStay);
        this.loadPosts(1000);
        this.resetPagination();
    }

    toggleMaxPeople(e) {
        const el = e.target;
        let value = parseInt(e.target.value);
        this.toggleTag(e);
        if (el.checked) {
            this.maxPeople.push(value);
        } else {
            this.maxPeople = removeFromArray(this.maxPeople, value);
        }
        this.toggleCount(e.target.parentElement.parentElement.parentElement, this.maxPeople);
        this.loadPosts(1000);
        this.resetPagination();
    }

    toggleCabinSize(e) {
        const el = e.target;
        let value = parseInt(e.target.value);
        this.toggleTag(e);
        if (el.checked) {
            this.cabinSizes.push(value);
        } else {
            this.cabinSizes = removeFromArray(this.cabinSizes, value);
        }
        this.toggleCount(e.target.parentElement.parentElement.parentElement, this.cabinSizes);
        this.loadPosts(1000);
        this.resetPagination();
    }

    toggleCount(el, arr) {
        let count = el.querySelector('.selection-count');
        count.innerText = arr.length;
        if (arr.length > 0) {
            show(count);
        } else {
            hide(count);
        }

    }

    switchPage(event) {
        let data = event.currentTarget.dataset;
        if (event.currentTarget.classList.contains('prev-page')) {
            this.currentPage--;
        }
        if (event.currentTarget.classList.contains('next-page')) {
            this.currentPage++;
        }
        if (data.page) {
            this.currentPage = parseInt(data.page);
        }
        //window.history.replaceState(null, null, '?accom_page='+this.currentPage);
        this.loadPosts(250);
    }

    createLoadingSpinner() {
        let loadingSpinnerContainer = document.createElement('div');
        let loadingSpinner = document.createElement('div');
        loadingSpinnerContainer.setAttribute('id', 'loadingSpinner');
        loadingSpinnerContainer.classList.add('spinner-container');
        loadingSpinner.classList.add('spinner');
        loadingSpinnerContainer.append(loadingSpinner);

        return loadingSpinnerContainer;
    }

    addPagination() {
        let paginationList = document.createElement('ul');
        paginationList.classList.add('pagination', 'list-unstyled');

        let paginationContainer = this.element.querySelector('#paginationNav');
        paginationContainer.innerHTML = '';


        if (this.maxPages > 0) {
            if (this.currentPage - 1 >= 1) {
                paginationList.innerHTML +=
                    '<li class="page-item">' +
                    '<a role="button" tabindex="0" class="page-link prev-page" data-action="accommodations-page#switchPage">' +
                    `<img src="${leftArrowUrl}" alt="Left pagination arrow" width="16" height="16">` +
                    '</a>' +
                    '</li>';

            } else {
                paginationList.innerHTML +=
                    '<li class="page-item disabled">' +
                    `<img src="${leftArrowUrl}" alt="Left pagination arrow" width="16" height="16">` +
                    '</li>';
            }

            let iter = 1;
            while (iter <= this.maxPages) {
                paginationList.innerHTML += '<li class="page-item">' +
                    `<a role="button" tabindex="0" class="page-link ${(iter === this.currentPage ? 'active' : '')}" data-page="${iter}" data-action="accommodations-page#switchPage">${iter}</a>` +
                    '</li>';
                iter++;
            }
            if (this.currentPage + 1 <= this.maxPages) {
                paginationList.innerHTML +=
                    '<li class="page-item">' +
                    '<a role="button" tabindex="0" class="page-link next-page" data-action="accommodations-page#switchPage">' +
                    `<img src="${rightArrowUrl}" alt="Left pagination arrow" width="16" height="16">` +
                    '</a>' +
                    '</li>';

            } else {
                paginationList.innerHTML +=
                    '<li class="page-item disabled">' +
                    `<img src="${rightArrowUrl}" alt="Left pagination arrow" width="16" height="16">` +
                    '</li>';
            }
        }
        paginationContainer.append(paginationList);
    }


    resetPagination() {
        this.currentPage = 1;
    }

    toggleTag(e) {
        const el = e.currentTarget;
        const id = 'tag-'+ el.id;
        if (el.checked) {
            let tagElement = document.createElement('div');
            tagElement.setAttribute('id', id);
            tagElement.classList.add('tag');
            tagElement.innerHTML = `<span>${this.findLabelForControl(el).innerText}</span><span data-action="click->accommodations-page#removeTag" class="remove-btn"></span>`;
            this.appliedFilters.insertBefore(tagElement, this.appliedFilters.firstChild);
            if (this.appliedFilters.children.length > 1) {
                fadeIn(document.getElementById('clearButton'), 'flex');
            }
        } else {
            if (document.getElementById(id)) {
                document.getElementById(id).remove();
                if (this.appliedFilters.children.length === 1) {
                    hide(document.getElementById('clearButton'));
                }
            }
        }
    }

    removeTag(e) {
        let element;
        if (e.target) {
            element = e.target;
        } else {
            element = e;
        }
        let checkbox = document.getElementById(element.parentElement.id.replace('tag-', ''));
        checkbox.checked = false;
        this.findLabelForControl(checkbox).classList.remove('dropdown_with-chk__list-item_active');
        element.parentElement.remove();
        if (this.appliedFilters.children.length === 1) {
            hide(document.getElementById('clearButton'));
        }

        let value = parseInt(checkbox.value);
        if (this.cabinSizes.includes(value)) {
            this.cabinSizes = removeFromArray(this.cabinSizes, value);
            this.toggleCount(checkbox.parentElement.parentElement.parentElement, this.cabinSizes);
        }

        if (this.maxPeople.includes(value)) {
            this.maxPeople = removeFromArray(this.maxPeople, value);
            this.toggleCount(checkbox.parentElement.parentElement.parentElement, this.maxPeople);
        }

        if (this.minStay.includes(value)) {
            this.minStay = removeFromArray(this.minStay, value);
            this.toggleCount(checkbox.parentElement.parentElement.parentElement, this.minStay);
        }

        if (this.facilities.includes(value)) {
            this.facilities = removeFromArray(this.facilities, value);
            this.toggleCount(checkbox.parentElement.parentElement.parentElement, this.facilities);
        }

        this.loadPosts(1000);

    }

    clearAll() {
        this.appliedFilters.querySelectorAll('.remove-btn').forEach((el)=>{
            this.removeTag(el);
        });
        this.element.querySelectorAll('.selection-count').forEach((el)=>{
            hide(el);
        });
    }

    findLabelForControl(el) {
        let idVal = el.id;
        let labels = document.getElementsByTagName('label');
        for( let i = 0; i < labels.length; i++ ) {
            if (labels[i].htmlFor === idVal)
                return labels[i];
        }
    }

    clearElements(parent, keep) {
        [...parent.children]
            .forEach(child => child !== keep ? parent.removeChild(child) : null);
    }


}