window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import { domReady } from './helpers';

import { initModal } from './components/modal.js';
import { Application } from '@hotwired/stimulus';
import { initNavbar } from './components/navbar';
import { initSlider } from './components/slider';
import { initLightBox } from './components/lightbox';
import { initDropdown } from './components/dropdown';
import initCookies from './components/cookies';
import { initCalendar, initRequestFormCalendar } from './components/calendar';

import AccommodationsPageController from './controllers/accommodations-page-controller';
import BoatsPageController from './controllers/boats-page-controller';
import LoaderController from './controllers/loader-controller';
import LeafletMapsController from './controllers/leaflet-maps-controller';
import TooltipController from './controllers/tooltip-controller';
import ModalController from './controllers/modal-controller';
import AxiosFormController from './controllers/axios-form-controller';

window.Stimulus = Application.start();

Stimulus.register('axios-form', AxiosFormController);
Stimulus.register('accommodations-page', AccommodationsPageController);
Stimulus.register('boats-page', BoatsPageController);
Stimulus.register('loader', LoaderController);
Stimulus.register('leaflet-map', LeafletMapsController);
Stimulus.register('tooltip', TooltipController);
Stimulus.register('modal', ModalController);

domReady(() => {
	initModal();
	initLightBox();
	initNavbar();
	initSlider();
	initDropdown();
	initCookies();
	initCalendar();
});

initRequestFormCalendar();