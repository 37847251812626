// eslint-disable-next-line no-unused-vars
import Swiper, {Pagination, Navigation, EffectFade, FreeMode, Autoplay} from 'swiper';

export function initSlider() {
    Swiper.use([Navigation, Pagination, EffectFade, FreeMode, Autoplay]);

    new Swiper('.hero-slider-block.slider.swiper', {
        loop: true,
        speed: 1000,
        pagination: {
            el: '.slider .slider-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.slider-pagination-next',
            prevEl: '.slider-pagination-prev',
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
    });

    new Swiper('.about-block .slider.swiper', {
        loop: true,
        speed: 1000,
        pagination: {
            el: '.slider .slider-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.slider-pagination-next',
            prevEl: '.slider-pagination-prev',
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
    });

    new Swiper('.activities-block .slider.swiper', {
        loop: true,
        speed: 1000,
        pagination: {
            el: '.slider .slider-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.slider-pagination-next',
            prevEl: '.slider-pagination-prev',
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
    });

    new Swiper('.gallery-slider-block .slider.swiper', {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
            el: '.slider .slider-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.slider-pagination-next',
            prevEl: '.slider-pagination-prev',
        },
        breakpoints: {
            960: {
                slidesPerView: 2,
            },
            1340: {
                slidesPerView: 3,
            },
        },
    });

    new Swiper('.gallery-grid.swiper', {
        slidesPerView: 'auto',
        loop: true,
        loopedSlides: 8,
        spaceBetween: 15,
        freeMode: true,
        autoplay: {
            delay: 3000,
        },
    });


}