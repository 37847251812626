export function initNavbar() {
    changeMenu();
    window.onscroll=function(){
        if (!document.getElementById('mobileMenu').classList.contains('mobile-menu-active')) {
            changeMenu();
        }
    };
    function changeMenu()
    {
        let scrollBarPosition = window.pageYOffset | document.body.scrollTop;
        let frontHeader = document.querySelector('#mastheadFront');
        if (frontHeader) {
            // At specifiv position do what you want
            if (scrollBarPosition > 25) {
                frontHeader.classList.remove('site-header-light');
            } else {
                frontHeader.classList.add('site-header-light');
            }
        }
    }

    let frontHeader = document.querySelector('#mastheadFront');
    document.getElementById('openMobileMenu').addEventListener('click', function () {
        if (!document.getElementById('mobileMenu').classList.contains('mobile-menu-active') && frontHeader) {
            frontHeader.classList.remove('site-header-light');
        } else {
            changeMenu();
        }

        document.getElementById('mobileMenu').classList.toggle('mobile-menu-active');
        document.body.toggleAttribute('mobile-nav-active');
        document.getElementById('openMobileMenu').toggleAttribute('mobile-nav-active');
    });


    // if ($(window).scrollTop() > 25) {
    //     $('#mastheadFront').removeClass('site-header-light');
    // }
    // $(window).scroll(function(){
    //     if ($(this).scrollTop() > 25) {
    //         $('#mastheadFront').removeClass('site-header-light');
    //     } else {
    //         $('#mastheadFront').addClass('site-header-light');
    //     }
    // });
}