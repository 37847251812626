import { Controller } from '@hotwired/stimulus';

const availableModalControllers = [];

export default class extends Controller {
  connect() {
    availableModalControllers.push(this);

    this.modalScrollContainer = this.element.querySelector('.modal-scroll-container');
    this.modalDialogContainer = this.element.querySelector('.modal-dialog');
    this.modalContent = this.element.querySelector('.modal-content');

    for (const close of this.element.querySelectorAll('.modal-close')) {
      close.addEventListener('click', () => this.hideModal());
    }

    this.modalScrollContainer.addEventListener('mousedown', (e) => {
      if (e.target === this.modalScrollContainer) {
        this.hideModal();
      }
    });

    this.modalDialogContainer.addEventListener('mousedown', (e) => {
      if (e.target === this.modalDialogContainer) {
        this.hideModal();
      }
    });

    this.modalContent.addEventListener('click', (e) => {
      e.stopPropagation();
    });

    this.bindModalOpenButtons();
  }

  bindModalOpenButtons() {
    for (const el of document.querySelectorAll('[data-modal-open="' + this.element.id + '"]')) {
      el.addEventListener('click', () => this.showModal());
    }
  }

  getOpenedModal() {
    for (const controller of availableModalControllers) {
      if (controller.element.classList.contains('modal-show')) {
        return controller;
      }
    }

    return null;
  }

  showModal(callback) {
    const currentModal = this.getOpenedModal();

    if (currentModal) {
      currentModal.hideModal(() => {
        this.showModalHandle(callback);
      });
    } else {
      this.showModalHandle(callback);
    }
  }

  showModalHandle(callback) {
    this.clearModalFormInputs();
    this.element.classList.add('modal-show');

    let op = 0.1;
    this.element.style.display = 'block';
    let timer = setInterval(() => {
      if (op >= 1) {
        clearInterval(timer);

        if (callback) {
          callback();
        }
      }
      this.element.style.opacity = op;
      this.element.style.filter = 'alpha(opacity=' + op * 100 + ')';
      op += op * 0.1;
    }, 5);

    document.getElementsByTagName('html')[0].classList.add('modal-visible');
  }

  hideModal(callback) {
    this.element.classList.remove('modal-show');

    let op = 1;
    let timer = setInterval(() => {
      if (op <= 0.1) {
        clearInterval(timer);
        this.element.style.display = 'none';

        if (callback) {
          callback();
        }
      }
      this.element.style.opacity = op;
      this.element.style.filter = 'alpha(opacity=' + op * 100 + ')';
      op -= op * 0.1;
    }, 5);

    document.getElementsByTagName('html')[0].classList.remove('modal-visible');
  }

  clearModalFormInputs() {
    const forms = this.element.querySelectorAll('form');

    for (const form of forms) {
      const generalErrors = form.querySelectorAll('.general-error');
      for (const el of generalErrors) {
        el.remove();
      }

      const generalSuccess = form.querySelectorAll('.general-success');
      for (const el of generalSuccess) {
        el.remove();
      }

      const fields = form.querySelectorAll('.input');
      for (const el of fields) {
        el.classList.remove('invalid');

        if (el.classList.contains('clear-before') && !el.classList.contains('ignore-clear-before')) {
          el.value = '';
        }

        if (el.hasAttribute('data-reset-to')) {
          el.value = el.getAttribute('data-reset-to');
        }
      }

      const hiddenFields = form.querySelectorAll('.axios-hide');
      for (const el of hiddenFields) {
        el.classList.remove('d-none');
      }

      const messageFields = form.querySelectorAll('.input-error');
      for (const el of messageFields) {
        el.remove();
      }
    }
  }
}
